@import "App";

body{
    overflow-x: hidden;
}
@for $i from 1 through 10{
    #RaceAnimList:nth-child(#{$i}){
        transition: 0.5s linear;
        transform: translateX(var(--r + #{$i})) translateY(calc(15 * $i) + px);
        z-index: #{$i};
    }
}