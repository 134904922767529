@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.App {
  display: grid;
  font-family: 'Press Start 2P', cursive;
  font-size: small;
}

:root{
  --r1: 0;
  --r2: 0;
  --r3: 0;
  --r4: 0;
  --r5: 0;
  --r6: 0;
  --r7: 0;
  --r8: 0;
  --r9: 0;
  --r10: 0;
}

h1,h2,h3,span{
  background: linear-gradient(90deg,magenta,cyan,pink);
  background-clip: text;
  color: #00000000;
}

#RaceTrackTitle h1,#RaceTrackTitle h2{
  background: none;
  background-clip: none;
  color: white;
}

*{
  padding: 0;
  margin: 0;
}

body{
  background:black;
}

button{
  padding: 10px;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000 inset;
  border-radius: 10px;
  color: white;
  font-family: 'Press Start 2P', cursive;
  font-size: small;
}

button:hover{
  cursor: pointer;
  box-shadow: none;
  color: black;
  transition: 0.5s ease;
  filter: drop-shadow(0 0 20px rgba(0, 255, 242, 0.3));
}

#Socials{
  display: flex;
  gap: 10px;
  align-items: center;
}
#Socials img:hover{
  transition: 1s ease;
  filter: drop-shadow(0 0 8px yellow);
}
#Socials img{
  width: 25px;
}

#Header{
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid yellow;
}

#SiteID{
  display: flex;
  align-items: center;
}

#SiteID img{
  width: 80px;
  margin-bottom: -5px;
}

#Header h1{
  font-family: "RetroRace";
}

#HeaderRight{
  display: flex;
  align-items: center;
  gap: 20px;
}

#Loader{
  display: grid;
  z-index: 1;
  color: white;
  background: black;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  align-content: center;
}

#RaceTrack{
  display: grid;
  width: 90%;
  gap: 10px;
  padding: 50px 5%;
  justify-self: center;
  border-bottom: 3px solid yellow;
  background: radial-gradient(gray -200%, black 80%);
}

#RaceTrackTitle{
  display: grid;
  gap: 10px;
}

#TitleButtons{
  visibility: hidden;
  display: flex;
  gap: 10px;
}

#RaceTrackCell{
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: max-content 1fr;
  justify-self: center;
  align-items: center;
}

#RacerListCell{
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

#RacerList{
  display: grid;
  justify-items: center;
  gap: 5px;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000 inset;
  color: white;
}

#RacerList img{
  width: 150px;
}

#RacerList span{
  font-size: 1em;
}

#RaceTrackRight{
  display: grid;
  gap: 20px;
  justify-items: center;
  -webkit-box-shadow: inset 0 0px 20px 2px black;
  filter: drop-shadow(0px 5px 10px black);
  border: 2px solid black;
  -webkit-border-radius: 20px;
  padding: 0 0px 20px 0px;
}

#RaceTrackRight img{
  -webkit-border-radius: 20px 20px 0 0;
  width: 300px;
}

#RaceTrackInfo{
  display: grid;
  gap: 20px;
  text-align: center;
}

#InfoCell{
  display: grid;
  gap: 10px;
}

#Information{
  display: grid;
  text-align: center;
  padding: 50px 5%;
  justify-self: center;
  gap: 50px;
  border-bottom: 3px solid yellow;
  width: 90%;
  background: linear-gradient(-20deg,rgba(255,0,255,20%),black 50%,rgba(255,0,0,20%));
}

#InformationCell{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#InformationCell img{
  width: 300px;
  height: auto;
  border-radius: 20px;
}
#InformationCell span{
  color: white;
}
#InfoCellRight{
  display: grid;
  text-align: right;
  gap: 10px;
  width: 50%;
  min-width: 360px;
}
#InfoCellLeft{
  display: grid;
  text-align: left;
  gap: 10px;
  width: 50%;
  min-width: 360px;
}

#RaceAnimation{
  display: grid;
  color: white;
  text-align: center;
  background-image: url("./images/raceanimbg.gif");
  background-size: 100% 100%;
  padding: 40px 0;
  border-bottom: 3px solid yellow;
}

#RaceAnimHead{
  -webkit-position: absolute;
  margin-top: -50px;
  justify-self: center;
  background: linear-gradient(0deg, darkgreen, limegreen);
  padding: 15px 10px;
  border-radius: 20px 20px 0 0;
  height: fit-content;
}

#RaceTable{
  display: grid;
  width: fit-content;
  height: fit-content;
  padding: 20px;
  border-radius: 20px;
  background: #00000080;
  border: 3px solid black;
  backdrop-filter: blur(10px);
  justify-self: center;
}

#RaceAnimationTrack{
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  width: fit-content;
  height: 250px;
  justify-self: center;
}

#RaceAnimList{
  display: grid;
  position: absolute;
  justify-items: center;
  gap: 5px;
  -webkit-height: fit-content;
}

#RaceAnimCar{
  width: 200px;
}

#RaceAnimPP{
  width: 50px;
  border-radius: 50px;
  border: 3px solid yellow;
}

#RaceAnimWinnerList{
  display: flex;
  gap: 20px;
}

#RaceAnimWinnerCell{
  display: grid;
  gap: 10px;
}

#Footer{
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background: black;
}

#TrackSelectorContainer{
  display: grid;
  gap: 20px;
  padding: 30px;
}

#TrackButtons{
  background: gray;
  width: fit-content;
  border-radius: 10px;
}

#TrackButtons button{
  padding: 20px;
  border: none;
  background-image: none;
  background-origin: none;
  background-clip: none;
  box-shadow: none;
  border-radius: 10px;
  color: black;
  font-family: 'Press Start 2P', cursive;
  font-size: small;
  background: none;
}

#TrackButtons button:hover{
  cursor: pointer;
  box-shadow: none;
  color: black;
  background: darkgray;
  border-radius: 10px;
  transition: 0s;
  filter: none;
}

@media only screen and (max-width:1000px){
  #Header{
    display: grid;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid yellow;
    gap: 10px;
  }

  #HeaderRight{
    display: grid;
    justify-items: center;
    gap: 10px;
  }


  #RaceTrack{
    display: grid;
    width: 90%;
    padding: 20px 5%;
    justify-self: center;
    border-bottom: 3px solid yellow;
    gap: 20px;
  }

  #TitleButtons{
    display: flex;
    gap: 10px;
    justify-self: center;
  }

  #RaceTrackRight{
    display: grid;
    gap: 20px;
    justify-items: center;
    background:radial-gradient(#00000000 35%,black 100%);
    border: 2px solid black;
    -webkit-border-radius: 20px;
    padding: 0 0px 20px 0px;
    width: fit-content;
    justify-self: center;
  }

  #RaceTrackCell{
    display: grid;
    grid-auto-flow: row;
    justify-self: center;
    justify-content: center;
    align-content: center;
    gap: 20px;
    width: 100%;
    grid-template-columns: 1fr;
  }

  #RacerListCell{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: fit-content;
    justify-self: center;
    gap: 10px;
  }

  #RaceTrackTitle{
    display: grid;
    gap: 10px;
    text-align: center;
    font-size: smaller;
  }
  
}

@media only screen and (max-width:500px){
  #Header{
    display: grid;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid yellow;
    gap: 10px;
  }

  #HeaderRight{
    display: grid;
    justify-items: center;
    gap: 10px;
  }

  #RaceTrack{
    display: grid;
    width: 90%;
    padding: 20px 5%;
    justify-self: center;
    border-bottom: 3px solid yellow;
    gap: 20px;
  }

  #TitleButtons{
    display: flex;
    gap: 10px;
    justify-self: center;
  }

  #RaceTrackRight{
    display: grid;
    gap: 20px;
    justify-items: center;
    background:radial-gradient(#00000000 35%,black 100%);
    border: 2px solid black;
    -webkit-border-radius: 20px;
    padding: 0 0px 20px 0px;
    width: fit-content;
    justify-self: center;
  }

  #RaceTrackCell{
    display: grid;
    grid-auto-flow: row;
    justify-self: center;
    justify-content: center;
    align-content: center;
    gap: 20px;
    width: 100%;
    grid-template-columns: 1fr;
  }

  #RacerListCell{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
  }

  #RacerListCell img{
    width: 65px;
  }
  #RacerListCell span{
    font-size: 5px;
  }

  #RaceTrackTitle{
    display: grid;
    gap: 10px;
    text-align: center;
    font-size: smaller;
  }
  #InformationCell{
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: 20px;
  }
  #InfoCellRight{
    display: grid;
    text-align: center;
    gap: 10px;
    width: 50%;
    min-width: 360px;
  }
  #InfoCellLeft{
    display: grid;
    text-align: center;
    gap: 10px;
    width: 50%;
    min-width: 360px;
  }
  #RaceAnimation{
    height: 300px;
  }
  #RaceAnimation #RacerListCell{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  #RaceAnimation #RacerList img{
    width: 50px;
  }
  #RaceAnimation #RacerList span{
    font-size: 4.5px;
  }
  #RaceAnimWinnerCell img{
    width: 100px;
  }
  #RaceAnimWinnerCell span{
    font-size: 9px;
  }
  #RaceAnimPP{
    width: 30px;
  }
  #RaceAnimCar{
    width: 120px;
  }
  #Footer{
    display: grid;
    gap: 10px;
    text-align: center;
    padding: 10px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background: radial-gradient(darkslategray -500%,black);
  }
  #TrackSelectorContainer{
    justify-self: center;
    justify-items: center;
    padding: 30px;
  }
  
}

@font-face{
  font-family: "RetroRace";
  src: local("RetroRace"),
  url("./fonts/RetroRace/HardRace.otf") format("opentype");
}